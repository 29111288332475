<script setup lang="ts"></script>

<template>
	<div>
		<ClientOnly>
			<div class="wrapper">
				<main>
					<slot />
				</main>
			</div>

			<template #fallback>
				<transition name="fade">
					<SplashScreen />
				</transition>
			</template>
		</ClientOnly>
	</div>
</template>

<style scoped lang="css">
.wrapper {
	@apply min-h-screen flex flex-col bg-white dark:bg-neutral-950 text-neutral-900 dark:text-neutral-50;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
